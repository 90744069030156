<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="px-0 d-flex justify-space-between align-center">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="onBack()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          :loading="exporting"
          style="width: fit-content; background-color: white;"
          @click="onExport()">
          <v-icon class="mr-2">
            mdi-tray-arrow-up
          </v-icon>
          <span>
            นำออกข้อมูล
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg mb-1">
          <v-col cols="12">
            <h3 class="primary--text">
              รายละเอียดแพ็กเกจ
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6">
            <table>
              <tr>
                <td class="table-padding">
                  เลขที่เวนเดอร์
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ tour.vendor.vendorNo }}
                </td>
              </tr>
              <tr>
                <td class="table-padding">
                  เวนเดอร์
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ tour.vendor.name }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-col
            cols="12"
            md="6">
            <table>
              <tr>
                <td class="table-padding">
                  รหัสแพ็กเกจทัวร์
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ tour.id }}
                </td>
              </tr>
              <tr>
                <td class="table-padding">
                  ชื่อแพ็กเกจทัวร์
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ tour.name }}
                </td>
              </tr>
              <tr>
                <td class="table-padding">
                  ประเทศ
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ tour.destination.translate.country }}
                </td>
              </tr>
              <tr>
                <td class="table-padding">
                  รอบการเดินทาง
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ tour.packageStartDate | dateFormat() }} - {{ tour.packageEndDate | dateFormat() }}
                </td>
              </tr>
              <tr>
                <td class="table-padding">
                  จำนวนคนเดินทาง
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ (tour.passengers || 0) | showNumberFormat() }} / {{ tour.crews | showNumberFormat() }} คน
                </td>
              </tr>
              <tr>
                <td class="table-padding">
                  ยอดการขายรวม
                </td>
                <td
                  v-if="tourLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ (tour.orderSales || 0) | showNumberFormat() }} บาท
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg">
          <v-col
            cols="12"
            md="4"
            class="d-flex align-center">
            <h3 class="primary--text">
              ประวัติการขาย
            </h3>
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="4">
            <SearchBox
              v-model="query.search"
              :disabled="loading"
              @search="getItems(true)" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :sort-by.sync="query.sortBy"
              :sort-desc.sync="query.sortOrder"
              hide-default-footer
              class="table-border">
              <template #[`item.createdAt`]="{ item }">
                {{ item.createdAt | dateFullFormat() }}
              </template>
              <template #[`item.fullName`]="{ item }">
                {{ `${item.booker.firstName} ${item.booker.lastName}` }}
              </template>
              <template #[`item.passengers`]="{ item }">
                {{ sumPassengers(item.passengers) | showNumberFormat() }}
              </template>
              <template #[`item.net`]="{ item }">
                ฿ {{ (item.net || 0) | showNumberFormat() }}
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-pagination
                v-model="query.page"
                :length="totalPages"
                :total-visible="7"
                circle
                color="primary" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { ExportToCsv } from 'export-to-csv'
import dayjs from 'dayjs'
import ReportsProvider from '@/resources/reports.provider'
import SearchBox from '@/components/SearchBox.vue'

const ReportsService = new ReportsProvider()

export default {
  components: { SearchBox },
  data: () => ({
    tourLoading: true,
    loading: true,
    exporting: false,
    headers: [
      {
        text: 'วันที่ทำรายการ',
        value: 'createdAt',
        class: 'table-header',
        align: 'center',
        width: '180'
      },
      {
        text: 'รหัสออเดอร์',
        value: 'orderNo',
        class: 'table-header',
        align: 'center',
        width: '180'
      },
      {
        text: 'รหัสลูกค้า',
        value: 'member.id',
        class: 'table-header',
        align: 'center',
        sortable: false,
        width: '180'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'fullName',
        class: 'table-header',
        cellClass: 'limit-text',
        sortable: false
      },
      {
        text: 'จำนวนผู้ร่วมเดินทาง',
        value: 'passengers',
        class: 'table-header',
        align: 'center',
        sortable: false,
        width: '180'
      },
      {
        text: 'ยอดการขาย',
        value: 'net',
        class: 'table-header',
        align: 'right',
        width: '180'
      }
    ],
    items: [],
    tour: null,
    query: {
      sortBy: '',
      sortOrder: true,
      page: 1,
      limit: 10,
      search: '',
      startDate: '',
      endDate: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.sortBy': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.sortOrder': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  async mounted () {
    this.getTourDetail()
    this.getItems()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'SalesReportDetail', params: { id: this.$route.params.id } })
    },
    sumPassengers (passengers) {
      return Object.keys(passengers).reduce((sum, key) => sum + passengers[key], 0)
    },
    async getTourDetail () {
      try {
        this.tourLoading = true

        const { data } = await ReportsService.getSalesReportByPackageDetail(this.$route.params.id, this.$route.params.startDate)

        if (!data) {
          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'ไม่พบเลขที่แพ็กเกจทัวร์นี้',
            type: 'error',
            timeout: 2000
          })

          this.$router.push({ name: 'SalesReport' })
          return
        }

        this.tour = data
      } catch (error) {
        console.error('getTourDetail', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.tourLoading = false
      }
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await ReportsService.getSalesReportByPackage(
          this.$route.params.id,
          this.$route.params.startDate,
          {
            ...this.query,
            sortBy: this.query.sortBy || 'createdAt',
            sortOrder: this.query.sortOrder || !this.query.sortBy ? 'desc' : 'asc'
          }
        )

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onExport () {
      try {
        this.exporting = true

        const { data } = await ReportsService.getSalesReportByPackage(
          this.$route.params.id,
          this.$route.params.startDate,
          {
            ...this.query,
            page: 1,
            limit: 99999,
            sortBy: this.query.sortBy || 'createdAt',
            sortOrder: this.query.sortOrder || !this.query.sortBy ? 'desc' : 'asc'
          }
        )

        const startDate = dayjs(this.$route.params.startDate).format('YYYY-MM-DD')

        const options = {
          filename: `sales-report-${this.$route.params.id}-date-${startDate}`,
          showLabels: false,
          useKeysAsHeaders: false
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv([
          {
            createdAt: 'วันที่ทำรายการ',
            orderNo: 'รหัสออเดอร์',
            member: 'รหัสลูกค้า',
            firstName: 'ชื่อ',
            lastName: 'นามสกุล',
            passengers: 'จำนวนผู้ร่วมเดินทาง',
            net: 'ยอดการขาย'
          },
          ...data.results.map((v) => ({
            createdAt: this.$options.filters.dateTimeUserFormat(v.createdAt),
            orderNo: v.orderNo,
            member: v.member.id,
            firstName: v.booker.firstName,
            lastName: v.booker.lastName,
            passengers: this.sumPassengers(v.passengers) || 0,
            net: v.net || 0
          }))
        ])
      } catch (error) {
        console.error('onExport', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.table-padding {
  font-weight: 600;
  padding-right: 16px;
  padding-bottom: 8px;
}
</style>
